<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="primary--text">{{ formEditTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="canSave()"
        >
          <v-row>
            <!-- Titulo -->
            <v-col cols="12" class="pb-0 mt-n2">
              <v-text-field
                v-model.trim="titulo"
                type="text"
                label="Titulo"
                dense
                :rules="rules.required.concat([rules.requiredTrim(titulo)])"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <!-- SubTitulo -->
            <v-col :cols="habilitarSubDinamic ? '6' : '12'" class="pb-0">
              <v-text-field
                v-model.trim="subtitulo"
                type="text"
                label="Subtítulo"
                v-if="this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile'"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="5"
              md="5"
              class="py-0 my-0 pb-0"
              v-if="
                habilitarSubDinamic &&
                  (this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile')
              "
            >
              <v-switch
                v-model="primerSubtitulo"
                label="Subtítulo dinámico"
              ></v-switch>
            </v-col>
            <v-col
              cols="1"
              class="text-left mt-1 ml-n10"
              v-if="
                habilitarSubDinamic &&
                  (this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile')
              "
            >
              <v-tooltip top max-width="20%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>
                  El subtítulo mostrará el primer registro del contenido.
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row
            :class="
              this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile'
                ? 'pb-0'
                : 'mt-n5'
            "
          >
            <!-- orden -->
            <v-col cols="3" class="pb-0" v-if="!this.isHome">
              <v-text-field
                v-model.trim="orden"
                type="text"
                label="Orden"
                dense
                :rules="
                  rules.required.concat(
                    MenuOrTab == 'tab'
                      ? rules.minMaxNumber(orden, 2, 100)
                      : rules.decimalGreaterThanZero(orden),
                    rules.max99
                  )
                "
                outlined
              ></v-text-field>
            </v-col>
            <!-- icono solo para cuando es profile -->
            <v-col cols="5" class="pb-0" v-if="this.isProfile">
              <v-text-field
                v-model="icon"
                type="text"
                :prepend-inner-icon="selectedIcon"
                label="Ícono"
                dense
                outlined
                class="icon-input"
                :rules="rules.IconStartWith.concat(rules.maxLength(icon, 100)).concat(rules.lowerCase)"
              >
                <template v-slot:prepend>
                  <div class="icon-container">
                    <font-awesome-icon :icon="icon" />
                  </div>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="1" class="text-left mt-2 ml-n3" v-if="this.isProfile">
              <v-tooltip top max-width="20%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>
                  El ícono debe ser de Fontawesome para ser renderizado por la
                  app, debe comenzar con 'fa-'. En caso que no empiecen con esas
                  letras, no se mostrará el ícono. Recibe solo minúsculas.
                </span>
              </v-tooltip>
            </v-col>
            <!-- Disponible -->
            <v-col cols="4" md="4" class="py-0" v-if="!this.isHomeOrProfile">
              <v-switch
                class="pt-0"
                v-model="disponible"
                label="Disponible"
              ></v-switch>
            </v-col>
            <!-- Opcion de menu o tab -->
            <v-col cols="5" class="py-0 mt-n1" v-if="!this.isHomeOrProfile">
              <v-radio-group
                column
                v-model="MenuOrTab"
                mandatory
                class="py-0 mt-2"
              >
                <v-radio
                  class="py-0"
                  label="Opción de menú"
                  value="menu"
                ></v-radio>
                <v-radio
                  class="py-0"
                  label="Opción tile"
                  value="tile"
                ></v-radio>
                <v-radio
                  class="py-0"
                  v-if="!this.anularTab"
                  label="Opción tab"
                  value="tab"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <!-- icono -->
            <v-col cols="5" class="py-0 pl-2" v-if="!this.isProfile">
              <v-text-field
                v-model="icon"
                type="text"
                :prepend-inner-icon="selectedIcon"
                label="Ícono"
                dense
                outlined
                class="icon-input"
                :rules="rules.IconStartWith.concat(rules.maxLength(icon, 100)).concat(rules.lowerCase)"
              >
                <template v-slot:prepend>
                  <div class="icon-container">
                    <font-awesome-icon :icon="icon" />
                  </div>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="1" class="text-left mt-n2 ml-n4" v-if="!this.isProfile">
              <v-tooltip top max-width="20%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>
                  El ícono debe ser de Fontawesome para ser renderizado por la
                  app, debe comenzar con 'fa-'. En caso que no empiecen con esas
                  letras, no se mostrará el ícono. Recibe solo minúsculas.
                </span>
              </v-tooltip>
            </v-col>
            <!-- Eleccion de color -->
            <v-col cols="6" class="py-0 mt-0">
              <v-card-text>
                <v-text-field
                  v-model="color"
                  outlined
                  dense
                  class="ma-0 pa-0 mt-n5"
                  :rules="[rules.maxLength(color, 20)]"
                  v-if="this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile'"
                >
                  <template v-slot:append>
                    <v-menu
                      v-model="menu"
                      nudge-bottom="105"
                      nudge-left="16"
                      max-width="500px"
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card class="pt-0">
                        <v-card-text class="ma-0 pt-0">
                          <v-row class="px-3 py-0">
                            <v-col></v-col>
                          </v-row>
                          <v-color-picker v-model="color" />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-card-text>
            </v-col>
          </v-row>
          <!-- Btn para adjuntar imagen -->
          <v-row v-if="this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile'">
            <v-col :class="imageUrl ? 'py-0' : 'py-0 mb-n7'">
              <v-btn
                raised
                class="grey lighten-2 mr-3"
                @click="seleccionarArchivo"
                small
              >
                Adjuntar imagen
              </v-btn>
              <v-tooltip top max-width="20%">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="primary">
                    {{ infoIcon }}
                  </v-icon>
                </template>
                <span>
                  Imágenes hasta 600x600 píxeles
                </span>
              </v-tooltip>
            </v-col>
            <!-- Btn para eliminar imagen -->
            <v-col align="right" v-if="imageUrl">
              <v-btn
                depressed
                raised
                @click="quitarImagen"
                color="error"
                x-small
              >
                Quitar imagen
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-if="this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile'">
            <input
              type="file"
              style="display: none"
              ref="fileNone"
              accept="image/*"
              :src="image"
              @change="onFilePicked"
            />
          </v-row>
          <v-row
            v-if="
              (this.MenuOrTab == 'menu' || this.MenuOrTab == 'tile') &&
                this.imageUrl != ''
            "
          >
            <!-- Espacio para mostrar imagen -->
            <v-col cols="12" md="6" offset-sm="3">
              <img :src="imageUrl" width="120" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions :class="this.MenuOrTab == 'tab' ? 'mt-n7' : 'mt-3'">
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModal"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
export default {
  name: "EditMenuTabsJoin",
  components: {
    FontAwesomeIcon
  },
  props: {
    editOpcionItem: {
      type: Object
    }
  },
  data: () => ({
    rules: rules,
    formEditTitle: "Editar opción",
    infoIcon: enums.icons.SNB_INFO,
    titulo: "",
    subtitulo: "",
    primerSubtitulo: false,
    disponible: false,
    orden: "",
    isFormValid: false,
    imageUrl: "",
    image: null,
    idImagen: null,
    files: null,
    color: null,
    menu: false,
    MenuOrTab: null,
    showDialog: false,
    habilitarSubDinamic: false,
    isHomeOrProfile: false,
    isHome: false,
    isProfile: false,
    anularTab: false,
    icon: "",
    selectedIcon: "mdi-check-outline",
    formData: null
  }),
  created() {
    this.setMenuTab(this.editOpcionItem);
  },
  watch: {
    icon() {
      if (this.icon.startsWith("mdi-")) {
        if (this.icon != this.selectedIcon && this.icon != "") {
          this.selectedIcon = this.icon;
        } else if (this.icon == "") {
          this.selectedIcon = "mdi-null";
        }
      } else if (this.icon.startsWith("fa-")) {
        if (this.icon != this.selectedIcon && this.icon != "") {
          this.selectedIcon = this.icon;
        } else if (this.icon == "") {
          this.selectedIcon = "mdi-null";
        }
      } else {
        this.selectedIcon = "mdi-null";
      }
    }
  },
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out"
      };
    }
  },
  methods: {
    ...mapActions({
      saveMenusTabsApp: "appBenef/saveMenusTabsApp",
      getImgMenuTabsApp: "appBenef/getImgMenuTabsApp",
      setAlert: "user/setAlert"
    }),
    async getAndSetImg(data) {
      this.idImagen = data;
      const response = await this.getImgMenuTabsApp(data);
      const resTransformation = URL.createObjectURL(response);
      this.imageUrl = resTransformation;
    },
    setMenuTab(data) {
      this.titulo = data.title;
      this.subtitulo = data.subtitle;
      this.primerSubtitulo = data.dynamicSubtitle;
      this.orden = data.order;
      this.disponible = data.enable;
      if (data.location == "menu") this.MenuOrTab = "menu";
      else if (data.location == "tile") this.MenuOrTab = "tile";
      else if (data.location == "tab") this.MenuOrTab = "tab";
      if (data.icon.startsWith("mdi-") || data.icon.startsWith("fa-")) {
        this.icon = data.icon;
        this.selectedIcon = data.icon;
      } else {
        this.icon = data.icon;
        this.selectedIcon = "";
      }
      if (data.color != null) this.color = data.color;
      else this.color = "#195472";
      if (data.image > 0) this.getAndSetImg(data.image);
      if (
        data.cod == "authorizations" ||
        data.cod == "currentAccount" ||
        data.cod == "messages"
      )
        this.habilitarSubDinamic = true;
      if (data.cod == "home" || data.cod == "profile") this.isHomeOrProfile = true;
      if(data.cod == "home") this.isHome = true;
      if(data.cod == "profile") this.isProfile = true;
      if (data.cod == "external-link") this.anularTab = true;
      if (data.cod == "links") this.anularTab = true;
    },
    async canSave() {
      this.isFormValid = false;
      if (this.subtitulo == null) this.subtitulo = "";
      this.formData = new FormData();
      this.formData.append("id", this.editOpcionItem.id);
      this.formData.append("cod", this.editOpcionItem.cod);
      this.formData.append("title", this.titulo);
      this.formData.append("subtitle", this.subtitulo);
      this.formData.append("redirectTo", this.editOpcionItem.redirectTo);
      this.formData.append("enable", this.disponible);
      this.formData.append("order", this.orden);
      this.formData.append("icon", this.icon);
      this.formData.append("dynamicSubtitle", this.primerSubtitulo);
      this.formData.append("location", this.MenuOrTab);

      if (this.MenuOrTab == "menu" || this.MenuOrTab == "tile") {
        if (this.image != null) {
          //Entra cuando se cambia la imagen.
          this.formData.append("image", 0);
          this.formData.append("imageFile", this.image);
        } else if (this.image == null && this.imageUrl != "") {
          //Entra cuando NO se cambia la imagen y esta la de antes.
          this.formData.append("image", this.idImagen);
          this.formData.append("imageFile", this.image);
        } else if (this.image == null && this.imageUrl == "") {
          //Entra cuando borra la imagen que habia. No hay nada.
          this.formData.append("image", 0);
          this.formData.append("imageFile", this.image);
        }
        this.formData.append("color", this.color);
      } else {
        this.formData.append("image", 0);
        this.formData.append("imageFile", null);
        this.formData.append("color", "");
      }

      if (this.image != null) this.formData.append("esImagen", true);
      else if (this.image == null) this.formData.append("esImagen", false);

      try {
        const res = await this.saveMenusTabsApp(this.formData);
        if (res.data === true) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.closeModal();
          this.isFormValid = true;
        }
      } catch {
        this.isFormValid = true;
      }
    },
    seleccionarArchivo() {
      this.$refs.fileNone.click();
    },
    
    onFilePicked(event) {
      const files = event.target.files; // este evento se activa al cambiar la entrada, para recuperar el archivo adjunto
      this.files = files[0];
      let filename = files[0].name; // recupera el nombre del archivo

      if (filename.lastIndexOf(".") <= 0) {
        //verifica si el nombre del archivo tiene "." en el último índice
        this.setAlert({
          type: "warning",
          message: "Por favor ingrese una imagen válida"
        });
        return;
      }

      const fileReader = new FileReader(); // convierto en string para que el lector de archivo de js pueda leerlo

      fileReader.addEventListener("load", () => {
        const image = new Image();

        image.onload = () => {
          const maxWidth = 600; // Ancho máximo permitido en píxeles
          const maxHeight = 600; // Alto máximo permitido en píxeles

          if (image.width > maxWidth || image.height > maxHeight) {
            this.setAlert({
              type: "warning",
              message: `La imagen es demasiado grande. El tamaño máximo permitido es ${maxWidth}x${maxHeight} píxeles.`
            });
            return;
          }

          this.imageUrl = fileReader.result;
          this.image = files[0];
        };

        image.src = fileReader.result;
      });

      fileReader.readAsDataURL(files[0]);
    },

    quitarImagen() {
      this.imageUrl = "";
      this.files = null;
      this.image = null;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
<style scoped>
.icon-input .icon-container {
  position: absolute;
  left: 20px;
  top: 30%;
  transform: translateY(-50%);
}
.icon-input .v-input__control input {
  padding-left: 40px;
  padding-right: 30px;
}
::v-deep label {
  margin-bottom: 0;
}

</style>
