<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card>
          <v-data-table
            :headers="headers"
            :items="MenuTabsItems"
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            item-key="id"
          >
            <template v-slot:[`item.enable`]="{ item }">
              <v-icon v-if="item.enable" small color="primary">
                {{ checkIcon }}
              </v-icon>
            </template>
            <template v-slot:[`item.location`]="{ item }">
              <span v-if="item.location == 'tile'">Tile</span>
              <span v-else-if="item.location == 'menu'">Menú</span>
              <span v-else-if="item.location == 'tab'">Tab</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="7" md="6">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openModalEdit(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-6 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="isOpenModalEdit"
      v-model="isOpenModalEdit"
      max-width="40%"
      @keydown.esc="closeAndReload"
      persistent
    >
      <EditMenuTabs
        :editOpcionItem="editOpcionItem"
        @closeAndReload="closeAndReload"
      ></EditMenuTabs>
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditMenuTabs from "@/components/modules/appBenef/configuracion/EditMenuTabsJoin.vue";
export default {
  name: "MenuTabsJoin",
  components: { PageHeader, GoBackBtn, Ayuda, EditMenuTabs },
  data: () => ({
    routeToGo: "ConfiguracionAppBenef",
    editIcon: enums.icons.EDIT,
    searchIcon: enums.icons.SEARCH,
    checkIcon: enums.icons.CHECK_OUTLINE,
    title: enums.titles.OPCIONES_MENU_TABS,
    search: "",
    optionCode: enums.webSiteOptions.MENU_TABS,
    editOpcionItem: null,
    MenuTabsItems: [],
    headers: [
      {
        text: "Título",
        value: "title",
        sortable: false,
      },
      {
        text: "Subtítulo",
        value: "subtitle",
        sortable: false,
      },
      {
        text: "Orden",
        value: "order",
        sortable: false,
        align: "end"
      },
      {
        text: "Categoría",
        sortable: false,
        value: "location",
      },
      {
        text: "Disponible",
        value: "enable",
        sortable: false,
        align: "center",
      },
      { text: "Acciones", value: "actions", sortable: false, align: "end" },
    ],
    isLoading: false,
    showExpand: false,
    showHelp: false,
    showIcon: true,
    canEdit: false,
    isOpenModalEdit: false,
  }),
  mounted(){
    if (this.$route.params.canEdit == undefined) {
      this.$router.push({
        name: "ConfiguracionAppBenef"
      });
    }
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false,
    });
    this.canEdit = this.$route.params.canEdit;
    this.loadOpcionesMenusTabs();
  },
  methods: {
    ...mapActions({
      getMenusTabsApp: "appBenef/getMenusTabsApp",
      setAlert: "user/setAlert",
    }),
    async loadOpcionesMenusTabs() {
      this.isLoading = true;
      const response = await this.getMenusTabsApp();
      this.MenuTabsItems = response;
      this.isLoading = false;
    },
    openModalEdit(item) {
      this.isOpenModalEdit = true;
      this.editOpcionItem = item;
    },
    closeAndReload() {
      this.isOpenModalEdit = false;
      this.loadOpcionesMenusTabs();
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
  },
};
</script>
<style scoped>
</style>